import styles from './PageHeader.module.scss';

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import cx from 'classnames';
import {Navbar} from 'reactstrap';
import {ImageLogo} from 'components/Images';
import {useAuth} from 'contexts/AuthContext';
import PublicNav from './PublicNav';
import PrivateNav from './PrivateNav';
import throttle from 'lodash/throttle';

PageHeader.propTypes = {
  logoSize: PropTypes.oneOf(['md', 'lg']),
};

PageHeader.defaultProps = {
  logoSize: 'md',
  dark: false,
  fluid: true,
  fixed: true,
  sticky: false,
};

function useSticky(isSticky) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > 200 && !isScrolled) {
        setIsScrolled(true);
      } else if (window.scrollY <= 200 && isScrolled) {
        setIsScrolled(false);
      }
    }, 100);

    if (isSticky)
      window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      if (isSticky) window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky, isScrolled]);

  return {isScrolled};
}

function PageHeader(props) {
  const {logoSize, dark, fluid, sticky, className, fixed} = props;
  const {isAuth} = useAuth();
  const {isScrolled} = useSticky(sticky);

  const themeDark = sticky && isScrolled ? !dark : dark;
  const _logoSize = sticky && isScrolled ? 'md' : logoSize;

  const _className = cx(
    styles['header'],
    {
      [styles['header--fluid']]: fluid,
      [styles['header--light']]: !themeDark,
      [styles['header--fixed']]: fixed,
      [styles['header--sticky']]: sticky && isScrolled,
    },

    className
  );

  return (
    <React.Fragment>
      {/* So the navigation is not broken when scrolling */}
      {sticky && isScrolled && (
        <div className={styles['header--sticky-filler']} />
      )}
      <header className={_className}>
        <Navbar
          expand="xl"
          dark={themeDark}
          className={cx('container-fluid', styles['header-nav'])}
        >
          <Link to="/">
            <ImageLogo size={_logoSize} />
          </Link>

          {isAuth ? (
            <PrivateNav dark={themeDark} />
          ) : (
            <PublicNav dark={themeDark} />
          )}
        </Navbar>
      </header>
    </React.Fragment>
  );
}

export default PageHeader;
