import styles from './im-sidebar.module.scss';

import React from 'react';
import {get} from 'lodash/object';
import {NavLink} from 'react-router-dom';

import {useAuth} from 'contexts/AuthContext';
import {ACCOUNT_ROLES, COMPANY_TYPE_LEVEL, COMPANY_TYPES} from 'configs';
import {Badge} from 'reactstrap';
import {useSubscription} from 'contexts/SubscriptionContext';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import classNames from 'classnames';
import {isEmpty} from 'lodash';

function PrivateSidebarNavsItem(props) {
  const {nav} = props;
  const {to, content, isNew, className} = nav;

  return (
    <li>
      <NavLink to={to}>
        <div className={classNames('d-flex align-items-center', className)}>
          {content}
          {isNew && (
            <div className="ml-2 align-items-center">
              <Badge color="danger" className="mb-1">
                New
              </Badge>
            </div>
          )}
        </div>
      </NavLink>
    </li>
  );
}

function PrivateSidebarNavs(props) {
  const {value, hr = true} = props;
  const {header, navs} = value;

  if (isEmpty(navs)) {
    return false;
  }

  return (
    <>
      <div className="my-3">
        {!!header && <small>{header}</small>}
        <ul>
          {(navs || []).map((nav, index) => (
            <PrivateSidebarNavsItem key={index} nav={nav} />
          ))}
        </ul>
      </div>
      {!!hr && <hr />}
    </>
  );
}

function PrivateSidebar() {
  const auth = useAuth();
  const {isSubscribed} = useSubscription();
  const companyType = get(auth, 'account.company.company_type', '');
  const isRegulator =
    get(auth, 'account.company.subscription_types.subscription_types_code') ===
    'regulator';
  const isCE = companyType === COMPANY_TYPES.ce;
  const companyLevel = COMPANY_TYPE_LEVEL[companyType];
  const isCompany = companyLevel === COMPANY_TYPE_LEVEL['company'];
  const isMNCB = companyLevel === COMPANY_TYPE_LEVEL['mncb'];

  const isAdmin = get(auth, 'account.account_role') === ACCOUNT_ROLES.admin;
  const isStaff = get(auth, 'account.account_role') === ACCOUNT_ROLES.staff;
  const isSuperAdmin =
    get(auth, 'account.account_role') === ACCOUNT_ROLES.super_admin;

  const overviews = {
    header: 'Overview',
    navs: [
      {
        to: '/dashboard',
        content: 'Dashboard',
      },
    ],
  };

  const data = {
    header: 'Listing',
    navs: [
      ENABLE_SUBSCRIPTION && {
        isNew: true,
        to: '/company-list',
        content: 'Companies',
        className: 'onboard-companies-tab',
      },
      isCE && {
        to: '/certifications',
        content: 'My Certifications',
      },
      ENABLE_SUBSCRIPTION && {
        isNew: true,
        to: '/certifications-list',
        content: 'Certifications',
        className: 'onboard-certs-tab',
      },
      (!ENABLE_SUBSCRIPTION ||
        isSubscribed ||
        (!isSubscribed && isSuperAdmin)) && {
        to: '/watch-list',
        content: 'Watchlist',
        isNew: isCE,
      },
    ].filter((v) => v),
  };

  const verifications = {
    header: 'Bulk Verification',
    navs: [
      ((isStaff && isSubscribed) ||
        (isAdmin && isSubscribed) ||
        isSuperAdmin) && {
        isNew: true,
        to: '/import-management',
        content: 'Import Company Data',
        className: 'onboard-import-tab',
      },
      // {
      //   isNew: true,
      //   to: '/api-settings',
      //   content: 'API Settings',
      // },
      isSubscribed && {
        isNew: true,
        to: '/import-settings',
        content: 'Import Settings',
        className: 'onboard-import-settings-tab',
      },
    ].filter((v) => v),
  };

  const history = {
    header: 'History & Messages',
    navs: [
      !isMNCB && {
        to: '/enquiries',
        content: 'Enquiries',
      },
      {
        to: '/search-history',
        content: 'Search History',
        isNew: isCE,
      },
    ].filter((v) => v),
  };

  const settings = {
    header: 'Settings',
    navs: [
      isSuperAdmin && {to: '/users', content: 'Users'},
      isCE && {to: '/ce-profile', content: 'Company Profile'},
      isCompany && {to: '/company-profile', content: 'Company Profile'},
      ENABLE_SUBSCRIPTION &&
        !isRegulator &&
        isSuperAdmin && {
          isNew: true,
          to: '/membership',
          content: 'Membership & Billing',
          className: 'onboard-membership-tab',
        },

      {to: '/settings', content: 'Settings'},
    ].filter((v) => v),
  };

  return (
    <nav className={styles['private-sidebar']}>
      <PrivateSidebarNavs value={overviews} />
      <PrivateSidebarNavs value={data} />
      {ENABLE_SUBSCRIPTION && (isSuperAdmin || isSubscribed) && (
        <PrivateSidebarNavs value={verifications} />
      )}
      <PrivateSidebarNavs value={history} />
      <PrivateSidebarNavs value={settings} hr={false} />
    </nav>
  );
}

export default PrivateSidebar;
